import { useState, useEffect } from 'react';
import { sendRequest } from "../server";

export const TableList = props => {
    const [data, setData] = useState(null);
    const [newField, setNewField] = useState("");
    const { tableName, setView, heading, col2Heading, col2Key } = props;

    useEffect(() => {
        sendRequest("table-list", { table: tableName }, (result) => { 
            const obj = JSON.parse(result);
            obj.sort((a, b) => a[col2Key] < b[col2Key] ? -1 : 1);
            setData(obj)
        });
    }, []);

    const handleInsert = () => {
        sendRequest(tableName+"-insert", { [col2Key]: newField }, () => alert("Insert Successful"));
    }
    
    return <>{ data === null ? "Loading... " :
        <div className="table-list">
            <h3>{heading} List</h3>
            <table>
                <thead><tr>
                    <th>ID</th>
                    <th>{col2Heading}</th>
                </tr></thead>
                <tbody>
                    { data.map(row => 
                        <tr key={row.id}>
                            <td>{row.id}</td>
                            <td className="fake-link" onClick={() => setView({
                                name: tableName+"-edit",
                                params: { id: row.id }
                            })}>{row[col2Key]}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <h4>New {heading} Name</h4>
            <input value={newField} onChange={e => setNewField(e.target.value)}></input>
            <button onClick={handleInsert}>Insert</button>
        
        </div>
    }</>
}