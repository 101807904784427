import { useState, useEffect } from 'react';
import { sendRequest } from "../server";
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism.css'; 

const deepClone = obj => JSON.parse(JSON.stringify(obj));

export const SubmissionView = props => {
    const { studentId, projectId, filename, groupBy, studentName } = props.params;
    const [data, setData] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [propsForNextStudent, setPropsForNextStudent] = useState(null);

    // console.log(data);
    // console.log(selectedSub);

    useEffect(() => {
        sendRequest("submissions", { groupBy, filename, projectId, studentId }, (result) => setData(JSON.parse(result)));
        sendRequest("get-next-student", studentId, result => {
            result = JSON.parse(result);
            const nsProps = {...props.params};
            nsProps.studentId = result.id;
            nsProps.studentName = result.last_name+", "+result.first_name;
            setPropsForNextStudent(nsProps);
        })
        
    }, [studentId]);

    const selectedSub = selectedIndex === null ? null : Object.freeze(data[selectedIndex]);

    const getHighlightedCode = () => {
        if (!selectedSub || !selectedSub.submission)
            return "";
        let highlightedCode = highlight(selectedSub.submission.code, languages.python);
        highlightedCode = highlightedCode.replaceAll(/\r?\n/g, "<br>");
        highlightedCode = highlightedCode.replaceAll("    ", "&nbsp;&nbsp;&nbsp;&nbsp;");
        return `${highlightedCode}`;
            
    }

    const handleTextAreaChange = (text) => {
        const dataCopy = deepClone(data);
        dataCopy[selectedIndex].submission.teacher_message = text;
        setData(dataCopy);   
    }

    const selectAllCode = () => {
        window.getSelection().selectAllChildren(document.getElementById("submission-code"));
    }

    const handleEditTeacherMessage = () => {
        let sendData;
        if (groupBy === "filename") {
            sendData = { 
                studentId: selectedSub.id, 
                message: selectedSub.submission.teacher_message, 
                projectId, 
                filename 
            }
        }
        else {
            sendData = {
                studentId,
                message: selectedSub.submission.teacher_message, 
                projectId,
                filename: selectedSub.fn,
            }
        }
        sendRequest("editTeacherMessage", sendData, () => alert("Save Successful"))
    }

    return data === null ? <>"Loading... "</> : <>
        <div>
            <hr/>
            {groupBy === "filename" ? <h3>File: {filename}</h3> : 
                <h3>Student: {studentName} (<span onClick={() => props.setView({
                    name: "submission", params: propsForNextStudent
                })}>next</span>)</h3>}
        </div>
        <div className="submission-container">

            <div className="submission-list">
                { data.map((sub, i) => <Submission 
                    key={i}
                    index={i}
                    sub={sub}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex} 
                    groupBy={groupBy}
                />) }
            </div>
            <div 
                className="submission-code" 
                id="submission-code"
                dangerouslySetInnerHTML={{__html: getHighlightedCode()}}
                onDoubleClick={selectAllCode}
            >
            </div>
            <div className="submission-tests">
                { selectedSub && selectedSub.submission && 
                    <p className="student-message"><b>Student Message: </b>{selectedSub.submission.student_message}</p>
                }
                { selectedSub && selectedSub.submission && <>
                    <b>Teacher Message:</b><br/>
                    <textarea 
                        value={selectedSub.submission.teacher_message === null ? "" : selectedSub.submission.teacher_message } 
                        style={{ width: 200, height: 100 }}
                        onChange={e => handleTextAreaChange(e.target.value)}
                    >
                    </textarea>
                    <br/>
                    <button onClick={handleEditTeacherMessage}>Save</button>
                    <br/><br/>
                    </>
                }
                {   (!selectedSub || !selectedSub.submission || !selectedSub.submission.test_results) ? "" : 
                        <TestResults testResults={selectedSub.submission.test_results}/>
                }
            </div>
        </div>
    </>

}

const Submission = props => {
    const { sub, index, setSelectedIndex, selectedIndex, groupBy } = props;

    let classes = sub.submission ? "student-entry fake-link submitted" : "student-entry fake-link unsubmitted";
    if (index === selectedIndex)
        classes += " selected-student";

    let testsPassed = 0;
    let numTests;
    if (sub.submission) {
        let results = sub.submission.test_results;
        let tests = [];
        tests = tests.concat(results && Array.isArray(results.runtimeResults) ? results.runtimeResults : []);
        tests = tests.concat(results && Array.isArray(results.structuralResults) ? results.structuralResults : []);
        tests.forEach(test => { testsPassed += test.result ? 1 : 0 });
        numTests = tests.length;
    }

    const text = groupBy === "filename" ? 
        <>{sub.last_name}, {sub.first_name} { sub.submission && <>({testsPassed}/{numTests})</> }</> :
        <>{sub.fn} { sub.submission && <>({testsPassed}/{numTests})</> }</>

    return <div className={classes} onClick={() => setSelectedIndex(index) } key={sub.id}>
        {text}
    </div>

}

const TestResults = props => {
    const { structuralResults, runtimeResults } = props.testResults;

    let tests = [];
    tests = tests.concat(Array.isArray(runtimeResults) ? runtimeResults : []);
    tests = tests.concat(Array.isArray(structuralResults) ? structuralResults : []);

    const getRow = (test, i) => 
        <tr key={i}>
            <td className="test-result test-result-pass-fail">
                { test.result ? 
                    <span className='test-result-pass'>PASS</span> : 
                    <span className='test-result-fail'>FAIL</span> 
                }
            </td>
            <td className="test-result test-result-description">
                { test.details ? 
                    <details>
                        <summary>{test.name}</summary>
                        {getDetailsTable(test.details)}
                        {getDetailsMessage(test.earlyTermination, test.error)}
                    </details> :
                    <>&#x25CF; {test.name}</>
                }
            </td>
        </tr>

    const getDetailsTable = details => <table className="test-result-details-table">
        <thead>
            <tr>
                <th>Expected</th>
                <th>Received</th>
                <th>Pass/Fail</th>
            </tr>
        </thead>
        <tbody>
            { details.map((test, i) => <tr key={i}>
                <td dangerouslySetInnerHTML={{__html: test.expected}}></td>
                <td dangerouslySetInnerHTML={{__html: test.received}}></td>
                <td>{test.pass ? "Pass" : "Fail"}</td>
            </tr>)}
        </tbody>
    </table>

    const getDetailsMessage = (earlyTermination, error)=> <>
        { earlyTermination && 
            <p className="test-result-early-termination-message">
                Program ended before all required input and output was received.
            </p>
        }
        
        { error && <>
            <p className="test-result-early-termination-message">
                Program ended with the following error message:
            </p>
            <div dangerouslySetInnerHTML={{__html: error.message}}></div>
            </>
        }
    </>

    return <table className='test-results-table'>
        <tbody>
            { tests.map((test, i) => getRow(test, i)) }
        </tbody>
    </table>   
}
