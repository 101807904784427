import { useState, useEffect } from 'react';
import { sendRequest } from "../server";

export const StudentEdit = props => {
    const [loaded, setLoaded] = useState(false);
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [username, setUsername] = useState("");   
    const [bsKey, setBsKey] = useState("");   
    const [projectList, setProjectList] = useState([]);

    useEffect(() => {
        sendRequest("student-get", { id: props.id }, (result) => { 
            const { student, projects } = JSON.parse(result);
            setLoaded(true);
            setFirstName(student.first_name ? student.first_name : "");
            setLastName(student.last_name ? student.last_name : "");
            setUsername(student.username ? student.username : ""); 
            setBsKey(student.bs_key ? student.bs_key : "");
            setProjectList(projects);
        });
    }, []);


    const handleSave = () => {
        sendRequest("student-update", { 
            id: props.id, 
            updateData: { last_name: lastName, first_name: firstName, username, bs_key: bsKey }
        }, () => alert("Successfully Saved"));

    }

    return <>{ !loaded ? "Loading... " :
        <div>
            <h3>Edit Student</h3>
            <h4>Last Name</h4>
            <input value={lastName} onChange={e => setLastName(e.target.value)}></input>
            <h4>First Name</h4>
            <input value={firstName} onChange={e => setFirstName(e.target.value)}></input>
            <h4>Username</h4>
            <input value={username} onChange={e => setUsername(e.target.value)}></input>
            <h4>Brightspace Key</h4>
            <input value={bsKey} onChange={e => setBsKey(e.target.value)}></input>
            <br/>
            <button onClick={handleSave}>Save</button>
            <button onClick={() => props.setView({ name: "student-list"})}>Back (no save)</button>
            <hr/>
            <div className="fake-link" onClick={() => props.setView({
                name: "student-summary",
                params: { id: props.id }
            })}>
                Show Summary
            </div>
            <h4>View Submissions</h4>
            {
                projectList.map(project => 
                    <div key={project.id} className="fake-link" onClick={() => props.setView({
                        name: "submission",
                        params: {  
                            projectId: project.id,
                            studentId: props.id,
                            groupBy: "student",
                            studentName: lastName+", "+firstName
                        }
                    })}>
                        {project.name}
                    </div>
                )
            }
            
        </div>
    }</>
}