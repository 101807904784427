import { useState, useEffect } from 'react';
import logo from './logo.svg';
import { sendRequest } from "./server";
import './App.css';
import { TableList } from "./views/table-list";
import { ProjectEdit } from "./views/project-edit";
import { StudentEdit } from "./views/student-edit";
import { SubmissionView } from "./views/submission-view";
import { StudentSummary } from "./views/student-summary";

function App() {
    const [view, setView] = useState({ name: "invalid-key"});
    const [isAdmin, setIsAdmin] = useState(false);
    
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    
    useEffect(() => {
        if (!params.key)
            return;

        if (params.key === "8465650") {
            setView({ name: "project-list"});
            setIsAdmin(true);
            return;
        }

        sendRequest("bs-key-to-student-id", { key: params.key }, id => {
            if (id !== 0) {
                setView({ name: "student-summary", params: {id} });
            }
        });
    }, [])

    return <div className="app-div">
        { isAdmin && <div>
            <h1>pNatural Admin</h1>
            <p>
                <span className="fake-link" onClick={() => setView({ name: "project-list" })}>Projects</span> | 
                <span className="fake-link" onClick={() => setView({ name: "student-list" })}>Students</span> 
            </p>
        </div>}

        { view.name === "invalid-key" && <>Invalid Key</> }

        { view.name === "project-list" && <TableList 
            setView={setView} 
            tableName="project" 
            heading="Project" 
            col2Heading="Name"
            col2Key="name"
        /> }
        { view.name === "project-edit" && <ProjectEdit setView={setView} id={view.params.id} /> }


        { view.name === "student-list" && <TableList 
            setView={setView} 
            tableName="student" 
            heading="Student" 
            col2Heading="Username"
            col2Key="username"
        /> }
        { view.name === "student-edit" && <StudentEdit setView={setView} id={view.params.id} /> }

        { view.name === "submission" && <SubmissionView 
            setView={setView}
            params={view.params}
        /> }

        { view.name === "student-summary" && <StudentSummary setView={setView} id={view.params.id}/> }
    </div>;

}

export default App;
