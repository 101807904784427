import { useState, useEffect } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'; 
import { sendRequest } from "../server";
import { getTestResults } from './student-summary';

export const ProjectEdit = props => {
    const [loaded, setLoaded] = useState(false);
    const [name, setName] = useState("");
    const [files, setFiles] = useState("");
    const [submissionData, setSubmissionData] = useState(null);

    useEffect(() => {
        sendRequest("project-get", { id: props.id }, (result) => { 
            const obj = JSON.parse(result);
            setLoaded(true);
            setName(obj.projectData.name);
            setFiles(obj.projectData.required_files === null ? "" : obj.projectData.required_files);

            const students = obj.studentData;
            students.sort((a, b) => a.last_name < b.last_name ? -1 : 1);
            const studentIdToIndex = [];
            for (let i = 0; i < students.length; i++)
                studentIdToIndex[students[i].id] = i;

            const files = JSON.parse(obj.projectData.required_files);
            const fnToIndex = [];
            for (let i = 0; i < files.length; i++) 
                fnToIndex[files[i].fn] = i;

            const subs = [];
            for (let i = 0; i < students.length; i++) {
                subs[i] = [];
                for (let j = 0; j < files.length; j++) {
                    subs[i][j] = false;
                }
            }
            
            for (let sub of obj.submissionData) {
                const studentIndex = studentIdToIndex[sub.student_id];
                const fileIndex = fnToIndex[sub.filename];
                if (studentIndex === undefined) {
                    console.log("Warning - Invalid student id: "+sub.student_id);
                    continue;
                }
                if (fileIndex === undefined) {
                    console.log("Warning - Invalid filename: "+sub.filename);
                    continue;
                }
                subs[studentIndex][fileIndex] = sub;
            }
            setSubmissionData({students, files, subs});
        });
    }, []);


    const handleSave = () => {
        try {
            JSON.parse(files);
        }
        catch (e) {
            alert(e.message);
            return;
        }
        sendRequest("project-update", { 
            id: props.id, 
            updateData: {
                name, 
                required_files: files 
            }
        }, () => alert("Successfully Saved"));

    }

    const getFilesAsObject = () => {
        try {
            return JSON.parse(files);
        }
        catch (e) {
            return [];
        }
    }

    return <>{ !loaded ? "Loading... " :
        <div>
            <h3>Edit Project</h3>
            <h4>Name</h4>
            <input value={name} onChange={e => setName(e.target.value)}></input>
            <h4>Required Files (JSON)</h4>
            <Editor
                value={files}
                onValueChange={code => setFiles(code)}
                highlight={code => highlight(code, languages.js)}
                padding={10}
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                }}
            />
            <br/>
            <button onClick={handleSave}>Save</button>
            <button onClick={() => props.setView({ name: "project-list"})}>Back (no save)</button>
            <hr/>
            <h4>View Submissions</h4>
            <ul>
                {   getFilesAsObject().map(file => <li 
                        key={file.fn}
                        className="fake-link"
                        onClick={() => props.setView({
                            name: "submission",
                            params: {
                                projectId: props.id, 
                                filename: file.fn, 
                                groupBy: "filename"
                            }
                        })}>
                            {file.fn}
                </li>)}
            </ul>
            <hr/>
            { submissionData !== null && <SubmissionSummary submissionData={submissionData} /> }
        </div>
    }</>
}

const SubmissionSummary = props => {
    const { submissionData } = props;
    const [curMessage, setCurMessage] = useState("");

    return <>
    <h4>Submission Summary</h4>
            <table>
                <thead>
                    <tr>
                        <th>Student</th>
                        {
                            submissionData.files.map(file => <th key={file.fn}>{file.fn}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        submissionData.students.map((student, studentIndex) =>
                            <tr key={studentIndex}>
                                <td>{student.last_name}, {student.first_name}</td>
                                {
                                    submissionData.subs[studentIndex].map((sub, fileIndex) => 
                                        <td 
                                            key={fileIndex}
                                            style={{backgroundColor: sub.test_results ? "lightgreen" : "pink"}}
                                            onClick={() => setCurMessage(sub.student_message)}
                                        >
                                            {sub ? getTestResults(sub.test_results) : "X"}
                                        </td>
                                    )
                                }
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <div className="student-message-in-summary">{curMessage}</div>
    </>
}