import { useState, useEffect } from 'react';
import { sendRequest } from "../server";

export const getTestResults = json => {
    const results = JSON.parse(json);
    let testsPassed = 0;
    let tests = [];
    tests = tests.concat(results && Array.isArray(results.runtimeResults) ? results.runtimeResults : []);
    tests = tests.concat(results && Array.isArray(results.structuralResults) ? results.structuralResults : []);
    tests.forEach(test => { testsPassed += test.result ? 1 : 0 });
    const numTests = tests.length;
    return ""+testsPassed+"/"+numTests;
}

export const StudentSummary = props => {
    const [student, setStudent] = useState(null);

    useEffect(() => {
        sendRequest("student-summary", { id: props.id }, (result) => setStudent(JSON.parse(result)));
    }, []);

    const getTeacherMessage = msg => {
        if (!msg)
            return "";
        if (msg.substring(0, 2) === "/e") msg = "Solution exceeds expectations. "+msg.substring(2);
        if (msg.substring(0, 2) === "/m") msg = "Solution meets expectations. "+msg.substring(2);
        if (msg.substring(0, 2) === "/a") msg = "Solution is approaching expectations. "+msg.substring(2);
        if (msg.substring(0, 2) === "/n") msg = "Solution does not yet meet expectations. "+msg.substring(2);
        
        return msg;
    }

    const getTableRows = (project) => project.files.map((file, i) => 
        <tr key={i}>
            <td>{file.filename}</td>
            <td>{file.due}</td>
            <td style={{ backgroundColor: file.submission ? "lightgreen" : "pink"}}>
                {file.submission ? "Yes" : "No"}
            </td>
            <td>{file.submission ? getTestResults(file.submission.testResults) : ""}</td>
            <td>{file.submission ? file.submission.studentMessage : ""}</td>
            <td>{file.submission ? getTeacherMessage(file.submission.teacherMessage) : ""}</td>
        </tr>
    )
    
    return <>{ student === null ? "Loading... " :
        <div>
            <h3>Summary for {student.first_name} {student.last_name}</h3>
            {   
                student.projects.map((project, i) => 
                    <div key={i}>
                        <h4>Project: {project.name}</h4>
                        <table>
                            <thead><tr>
                                <th>Program</th>
                                <th>Due Date</th>
                                <th>Submitted</th>
                                <th>Tests Passed</th>
                                <th>Student Comment</th>
                                <th>Teacher Comment</th>
                            </tr></thead>
                            <tbody>{getTableRows(project)}</tbody>
                        </table>
                    </div>
                )
            }
        
        </div>
    }</>
}