async function postData(url, headers, body) {
    const response = await fetch(url, { method: 'POST', headers, body });
    //console.log(response);
    return response.json(); 
}

const failCallback = error => {
    console.error("Server request failed with error: "+error);
}

const timeoutCallback = () => {
    console.error("server timeout");
}

export const sendRequest = (request, payload, successCallback) => {

    let url = "https://pn22adminserver.dstewart.ca/request";
    if (window.location.hostname === "localhost") {
        url = "http://localhost:8084/request";
    }

    //const protocol = window.location.hostname === "localhost" ? "http" : "https";
    //const url = `${protocol}://${window.location.hostname}:8084/request`;
    const headers = { 'Content-Type': 'application/json' };
    const body = JSON.stringify({ 
        type: request,
        payload
    })     

    // console.log({request, url, headers, body});

    postData(url, headers, body)
        .then(data => data.status === "success" ? successCallback(data.result) : failCallback(data.result))
        .catch((err) => {
            console.log(err);
            timeoutCallback()
        });
}
  